<template>
    <div class="contaners_share">
        <img style="width:100%;height:100%" src="../../../assets/backgroundicon.png" />
        <div @click="mineMessage" class="messageTitle13">
            <img class="messageIcon" src="../../../assets/phone2.png" />
            <div>联系客服</div>
        </div>
        <div class="content_share_flow">

            <div class="formSubmit">
                <div class="formList">
                    <div class="offine_title" style="letter-spacing: 10px;">姓名:</div>
                    <input v-model="dataForm.username.value" />
                </div>
                <div>
                    <van-field readonly clickable name="picker" :value="dataForm.sex.value" :label="dataForm.sex.title" @click="showPicker = true" />
                    <van-popup v-model="showPicker" position="bottom">
                        <van-picker show-toolbar :columns="dataForm.sex.list" @confirm="onConfirm1" @cancel="showPicker = false" />
                    </van-popup>
                </div>
                <div class="formList">
                    <div class="offine_title">联系方式:</div>
                    <input v-model="dataForm.phone.value" />
                </div>
                <div class="formList">
                    <div class="offine_title" style="letter-spacing: 5px;">微信号:</div>
                    <input v-model="dataForm.wxUser.value" />
                </div>
                <div class="formList">
                    <div class="offine_title">希望持股:</div>
                    <input type="number" v-model="dataForm.hopStake.value" />
                </div>
            </div>
            <div style="color:#fff" v-if="queryList.id" class="offine_text">*经系统检测,您的补金为:{{tableData.info_state==3?userFrom.member.available_assets:(Number(userFrom.member.available_assets)+Number(settingDate.audit.shares_assets))}},可持有</div>
            <div style="color:#fff" v-else class="offine_text">*经系统检测,您的补金为:{{userFrom.member.available_assets}},可持有</div>
            <div v-if="!settingDate.shareholder_activity">
                <div v-if="!queryList.id">
                    <div v-if="!queryList.id">
                        <div style="color:#fff" v-if="queryList.select==1" class="offine_text">最大股份为:{{Math.floor(userFrom.member.available_assets/settingDate.config.shares_cardinal_1)}}</div>
                        <div style="color:#fff" v-else class="offine_text">最大股份为:{{Math.floor(userFrom.member.available_assets/settingDate.config.shares_cardinal_2)}}</div>
                    </div>
                </div>
                <div style="color:#fff" v-else class="offine_text">
                    <div style="color:#fff" v-if="tableData.apply_type==1" class="offine_text">最大股份为:{{tableData.info_state==3?Math.floor(userFrom.member.available_assets/tableData.shares_cardinal):Math.floor(userFrom.member.available_assets/tableData.shares_cardinal)+Number(tableData.shares_amount)}}</div>
                    <div style="color:#fff" v-else class="offine_text">最大股份为:{{tableData.info_state==3?Math.floor(userFrom.member.available_assets/tableData.shares_cardinal):Math.floor(userFrom.member.available_assets/tableData.shares_cardinal)+Number(tableData.shares_amount)}}</div>
                </div>
            </div>
            <div v-else>
                <div v-if="!queryList.id">
                    <div style="color:#fff" v-if="queryList.select==1" class="offine_text">最大股份为:{{Math.floor(userFrom.member.available_assets/settingDate.shareholder_activity.shares_cardinal_1)}}</div>
                    <div style="color:#fff" v-else class="offine_text">最大股份为:{{Math.floor(userFrom.member.available_assets/settingDate.shareholder_activity.shares_cardinal_2)}}</div>
                </div>
                <div style="color:#fff" v-else class="offine_text">
                    <div style="color:#fff" v-if="tableData.apply_type==1" class="offine_text">最大股份为:{{tableData.info_state==3?Math.floor(userFrom.member.available_assets/tableData.shares_cardinal):Math.floor(userFrom.member.available_assets/tableData.shares_cardinal)+Number(tableData.shares_amount)}}</div>
                    <div style="color:#fff" v-else class="offine_text">最大股份为:{{tableData.info_state==3?Math.floor(userFrom.member.available_assets/tableData.shares_cardinal):Math.floor(userFrom.member.available_assets/tableData.shares_cardinal)+Number(tableData.shares_amount)}}</div>
                </div>
            </div>
            <div class="address">收货详细地址:</div>
            <textarea v-model="dataForm.address.value" class="shipping"></textarea>
            <div style="display:flex">
                <div v-if="iconShow==false" @click="checkoutBtn(1)" class="select_box"></div>
                <div v-else class="select_box" @click="checkoutBtn(2)" style="background:#87CEFA"></div>
                <div class="ruleBtn" @click="ruleBtn">我已熟知与查看《工会股东协议》</div>
            </div>
            <div class="office_submit" @click="submitBtn">
                <img src="../../../assets/submitBtn.png" />
            </div>
        </div>

        <!-- 提示框 -->
        <toast v-if="toastShow" :config="configData"></toast>

        <!-- 签字弹窗 -->
        <div class="signtureShow" v-if="signtureShow" @click="signtureShow">
            <div class="signtureShow_box">
                <catSignature :imgWidth="imgWidth" @signatureBtn="signatureBtn" @cloneBtn="cloneBtn1" :imgToken="imgToken" @cloneImg="cloneImg" @imgUblod="imgUblod"></catSignature>
            </div>
        </div>
    </div>
</template>
<script>
// import wx from "weixin-js-sdk";
import toast from "@/components/toast";
import * as apiCommon from "@/api/common";
import Cookies from "js-cookie";
import catSignature from "../../../components/catSignature.vue";
import {
  shareholderApply_api,
  getuser_api,
  getSetting,
  detailShareholderApply_api
} from "@/api/common";
export default {
  data() {
    return {
      dataForm: {
        username: {
          value: "",
          title: "姓名",
          text: "请输入姓名",
          color: "#DEB77A"
        },
        sex: {
          value: "",
          title: "性别:",
          text: "请输入性别",
          color: "#DEB77A",
          list: ["女", "男"]
        },
        phone: {
          value: "",
          title: "联系方式",
          text: "请输入联系方式",
          color: "#DEB77A"
        },
        wxUser: {
          value: "",
          title: "微信号",
          text: "请输入微信号",
          color: "#DEB77A"
        },
        hopStake: {
          value: "",
          title: "希望持股",
          text: "请输入希望持股",
          color: "#DEB77A"
        },
        nameImg: {
          value: "",
          title: "签名",
          text: "请签名",
          color: "#DEB77A"
        },
        address: {
          value: "",
          title: "收货详细地址",
          text: "请输入收货详细地址",
          color: "#DEB77A"
        }
      },
      checked: 1,
      iconShow: false,
      configData: {
        title: ""
      },
      toastShow: false,
      signtureShow: false,
      img: "",
      imgWidth: 645,
      imgToken: "",
      queryList: null,
      showPicker: false,
      // 用户信息
      userFrom: {
        member: {
          available_assets: ""
        }
      },
      settingDate: {
        config: {},
        audit: {}
      },
      tableData: {},
      submitFaly: true
    };
  },
  components: {
    catSignature,
    toast
  },
  created() {
    this.queryList = this.$route.query;
    (document.title = "线下股东报名"), this.getToken();
    this.getUser();
    // 获取配置
    this.getConfig();
    if (this.queryList.id) {
      this.getDetails(this.queryList.id);
    }
    if (Cookies.get("dataForm")) {
      this.dataForm = JSON.parse(Cookies.get("dataForm"));
    }
  },
  methods: {
    async getDetails(id) {
      let res = await apiCommon.getShareDetail_api(id);
      if (res.code == 0) {
        console.log(res.data);
        this.tableData = res.data;
        this.queryList.select = this.tableData.apply_type;
        this.dataForm.username.value = res.data.audit_info.name;
        this.dataForm.sex.value = res.data.audit_info.sex;
        this.dataForm.phone.value = res.data.audit_info.phone;
        this.dataForm.wxUser.value = res.data.audit_info.wxUser;
        this.dataForm.address.value = res.data.audit_info.address;
        this.dataForm.hopStake.value = res.data.shares_amount;
      }
    },
    getConfig() {
      getSetting().then(res => {
        console.log(res.status);
        if (res.code == 0) {
          this.settingDate = res.data;

          this.$forceUpdate();
        }
      });
    },
    getUser() {
      getuser_api().then(res => {
        console.log(res, "res");
        if (res.code == 0) {
          this.userFrom = res.data;
        }
      });
    },
    ruleBtn() {
      Cookies.set("dataForm", JSON.stringify(this.dataForm));
      this.$router.push("../rule");
    },
    cloneBtn1() {
      console.log(1);
      this.signtureShow = false;
      this.$forceUpdate();
    },
    onConfirm1(value) {
      this.dataForm.sex.value = value;
      console.log(this.config, "config");
      this.showPicker = false;
    },
    async getToken() {
      console.log(2);
      let res = await apiCommon.getImgToken();
      if (!res || typeof res === "string" || res.error)
        return this.$toast.fail(res ? res.error || res : "获取七牛云信息失败!");
      this.imgToken = res.data;
      console.log(this.imgToken, "1223131");
    },
    signatureBtn() {
      this.toastShow = true;
      this.configData.title = "签名不能为空";
      setTimeout(e => {
        console.log(e);
        this.toastShow = false;
      }, 1000);
    },
    imgUblod(img) {
      console.log(img, "1111111111");
      this.signtureShow = false;
      this.iconShow = true;
      this.dataForm.nameImg = img;
      this.toastShow = true;
      this.configData.title = "签名完成";
      setTimeout(() => {
        this.toastShow = false;
      }, 1000);
      this.popShow = true;
    },
    mineMessage() {
      // wx.closeWindow();
      this.$router.push("../../lador/service");
    },
    checkoutBtn(index) {
      if (index == 1) {
        this.signtureShow = true;
      } else [(this.iconShow = false)];
    },
    submitBtn() {
      if (!this.submitFaly) {
        this.toastShow = true;
        this.configData.title = "请勿重复提交";
        setTimeout(() => {
          this.toastShow = false;
        }, 1000);
        return false;
      }
      for (var i in this.dataForm) {
        if (!this.dataForm[i]) {
          this.toastShow = true;
          this.configData.title = this.dataForm.text;
          setTimeout(() => {
            this.toastShow = false;
          }, 1000);
        }
      }
      if (this.queryList.id) {
        this.detail();
      } else {
        this.save();
      }
    },
    cloneImg() {
      (this.dataForm.nameImg.value = ""), localStorage.removeItem("imgName");
    },

    save() {
      console.log(1);
      for (var i in this.dataForm) {
        if (!this.dataForm[i]) {
          this.toastShow = true;
          this.configData.title = this.dataForm.text;
          setTimeout(() => {
            this.toastShow = false;
          }, 1000);
          this.submitFaly = true;
        return false;
        }
      }
      if (!/^1[3456789]\d{9}$/.test(this.dataForm.phone.value)) {
        this.toastShow = true;
        this.configData.title = "请输入正确的手机号";
        setTimeout(() => {
          this.toastShow = false;
        }, 1000);
         this.submitFaly = true;
        return false;
      }
      let configSetting = null;
      // if(this.queryList.select==1){
      //     configSetting = Math.floor(this.userFrom.member.available_assets/this.settingDate.config.shares_cardinal_1)
      // }else{
      //     configSetting = Math.floor(this.userFrom.member.available_assets/this.settingDate.config.shares_cardinal_2)
      // }

      if (this.queryList.id) {
        if (this.queryList.select == 1) {
          configSetting =
            Math.floor(
              this.userFrom.member.available_assets /
                this.settingDate.config.shares_cardinal_1
            ) + Number(this.settingDate.audit.shares_amount);
        } else {
          configSetting =
            Math.floor(
              this.userFrom.member.available_assets /
                this.settingDate.config.shares_cardinal_2
            ) + Number(this.settingDate.audit.shares_amount);
        }
      } else {
        if (this.queryList.select == 1) {
          configSetting = Math.floor(
            this.userFrom.member.available_assets /
              this.settingDate.config.shares_cardinal_1
          );
        } else {
          configSetting = Math.floor(
            this.userFrom.member.available_assets /
              this.settingDate.config.shares_cardinal_2
          );
        }
      }
      console.log(configSetting, "configSetting");
      // 判斷可兌換股份
      if (this.dataForm.hopStake.value > configSetting) {
        this.toastShow = true;
        this.configData.title = "希望持股不能大于最大持股";
        setTimeout(() => {
          this.toastShow = false;
        }, 1000);
         this.submitFaly = true;
        return false;
      }

      // console.log(this.dataForm.hopStake.value<0,12313,this.dataForm.hopStake.value.indexOf('.')1=-1)
      if (
        this.dataForm.hopStake.value < 0 ||
        JSON.stringify(this.dataForm.hopStake.value).indexOf(".") != -1
      ) {
        this.toastShow = true;
        this.configData.title = "希望持股必须是正整数";
        setTimeout(() => {
          this.toastShow = false;
        }, 1000);
        this.submitFaly = true;
        return false;
      }
      let userData = {
        name: this.dataForm.username.value,
        sex: this.dataForm.sex.value,
        phone: this.dataForm.phone.value,
        wxUser: this.dataForm.wxUser.value,
        nameImg: this.dataForm.nameImg.value,
        address: this.dataForm.address.value
      };
      let data = {
        apply_type: this.queryList.select,
        shares_amount: this.dataForm.hopStake.value,
        audit_type: 2,
        audit_info: userData,
        mobile: this.dataForm.phone.value
      };

      shareholderApply_api(data).then(res => {
        this.submitFaly = true;
        if (res.code == 0) {
          Cookies.remove("dataForm");
          console.log(res.data.audit_id, res.data, "data");
          this.$router.push(`./flow?audit_id=${res.data.audit_id}`);
        } else {
          this.toastShow = true;
          this.configData.title = res.error;
          setTimeout(() => {
            this.toastShow = false;
          }, 1000);
        }
      });
    },
    detail() {
      for (var i in this.dataForm) {
        if (!this.dataForm[i]) {
          this.toastShow = true;
          this.configData.title = this.dataForm.text;
          setTimeout(() => {
            this.toastShow = false;
          }, 1000);
          this.submitFaly = true;
        return false;
        }
      }
      if (!/^1[3456789]\d{9}$/.test(this.dataForm.phone.value)) {
        this.toastShow = true;
        this.configData.title = "请输入正确的手机号";
        setTimeout(() => {
          this.toastShow = false;
        }, 1000);
         this.submitFaly = true;
        return false;
      }
      let configSetting = null;
      // if(this.queryList.select==1){
      //     configSetting = Math.floor(this.userFrom.member.available_assets/this.settingDate.config.shares_cardinal_1)
      // }else{
      //     configSetting = Math.floor(this.userFrom.member.available_assets/this.settingDate.config.shares_cardinal_2)
      // }
      if (this.queryList.id) {
        if (this.queryList.select == 1) {
          console.log(
            1,
            Number(
              Math.floor(
                this.userFrom.member.available_assets /
                  this.settingDate.config.shares_cardinal_1
              )
            ),
            Number(this.settingDate.audit.shares_amount)
          );
          configSetting =
            Number(
              Math.floor(
                this.userFrom.member.available_assets /
                  this.settingDate.config.shares_cardinal_1
              )
            ) + Number(this.settingDate.audit.shares_amount);
        } else {
          console.log(2);
          configSetting =
            Number(
              Math.floor(
                this.userFrom.member.available_assets /
                  this.settingDate.config.shares_cardinal_2
              )
            ) + Number(this.settingDate.audit.shares_amount);
        }
      } else {
        if (this.queryList.select == 1) {
          configSetting = Math.floor(
            this.userFrom.member.available_assets /
              this.settingDate.config.shares_cardinal_1
          );
        } else {
          configSetting = Math.floor(
            this.userFrom.member.available_assets /
              this.settingDate.config.shares_cardinal_2
          );
        }
      }
      console.log(configSetting, "configSetting");
      // 判斷可兌換股份
      if (this.dataForm.hopStake.value > configSetting) {
        this.toastShow = true;
        this.configData.title = "希望持股不能大于最大持股";
        setTimeout(() => {
          this.toastShow = false;
        }, 1000);
        this.submitFaly = true;
        return false;
      }
      if (
        this.dataForm.hopStake.value < 0 ||
        JSON.stringify(this.dataForm.hopStake.value).indexOf(".") != -1
      ) {
        this.toastShow = true;
        this.configData.title = "希望持股必须是正整数";
        setTimeout(() => {
          this.toastShow = false;
        }, 1000);
        this.submitFaly = true;
        return false;
      }
      let userData = {
        name: this.dataForm.username.value,
        sex: this.dataForm.sex.value,
        phone: this.dataForm.phone.value,
        wxUser: this.dataForm.wxUser.value,
        nameImg: this.dataForm.nameImg.value,
        address: this.dataForm.address.value
      };
      let data = {
        apply_type: this.queryList.select,
        shares_amount: this.dataForm.hopStake.value,
        audit_type: 2,
        audit_info: userData,
        mobile: this.dataForm.phone.value
      };

      detailShareholderApply_api(data, this.queryList.id).then(res => {
        this.submitFaly = true;
        if (res.code == 0) {
          this.$router.push(`./flow?audit_id=${this.queryList.id}`);
        } else {
          this.toastShow = true;
          this.configData.title = res.error;
          setTimeout(() => {
            this.toastShow = false;
          }, 1000);
        }
      });
    }
  }
};
</script>
<style >
.signtureShow {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
}
.signtureShow_box {
  width: 80%;
  background-color: #fff;
  margin: 150px auto 0;
  border-radius: 15px;
  padding-bottom: 20px;
}
.office_submit {
  background-color: #deb77a;
  border-radius: 50px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
  color: #fff;
}
.contaners_share {
  width: 100%;
  /* height:100vh; */
  position: relative;
  font-size: 30px;
  color: #deb77a;
}
.content_share_flow {
  position: absolute;
  top: 0;
  left: 0px;
  width: 666px;
  /* height:100vh; */
  /* margin-bottom: 200px; */
  margin: 0 100px 0;
}
.messageTitle13 .messageIcon {
  width: 36px;
  height: 36px;
  margin-right: 20px;
}
.messageTitle13 {
  text-align: right;
  margin-right: 20px;
  font-size: 20px;
  color: #fff;
  position: absolute;
  top: 20px;
  right: 10px;
  z-index: 3;
}
.header_contract {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.header_contract div {
  display: flex;
}
.header_contract img {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}
.contranct_text1 {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
  margin-top: 20px;
}
.contranct_text {
  font-size: 25px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #deb77a;
  opacity: 1;
  margin-top: 25px;
}
.contranct_box {
  width: 515px;
  height: 567px;
  margin: 291px auto 0;
  position: relative;
}
.contranct_box img {
  width: 100%;
  height: 100%;
}

.formSubmit {
  margin-top: 100px;
}
input {
  background-color: transparent;
  border: 1px solid #deb77a;
  width: 348px;
  height: 54px;
  padding-left: 20px;
}
.formList {
  display: flex;
  line-height: 54px;
  margin-bottom: 40px;
}
.offine_title {
  width: 150px;
  letter-spacing: 4px;
  font-size: 30px;
}
.offine_text {
  font-size: 25px;
  margin-bottom: 20px;
}
.shipping {
  width: 519px;
  height: 161px;
  border: 2px solid #deb77a;
  opacity: 1;
  border-radius: 5px;
  background-color: transparent;
  margin-bottom: 40px;
}
.address {
  margin: 48px auto 24px;
}

.ruleBtn {
  color: #deb77a !important;
  margin-left: 20px;
  font-size: 25px;
}
.select_box {
  width: 32px;
  height: 32px;
  border: 1px solid #deb77a;
  opacity: 1;
  border-radius: 0px;
}
.office_submit {
  width: 206px;
  height: 68px;
  margin: 100px 157px 0;
}
.office_submit img {
  width: 100%;
  height: 100%;
}

.van-cell {
  font-size: 30px;
  width: 560px;
  height: 60px;
  background-color: transparent;
  /* margin-left: 30px; */
  margin-bottom: 30px;
  padding: 0;
  /* border: 1px solid #DEB77A;
    width: 348px;
    height: 54px; */
}
.van-picker-column {
  font-size: 30px;
}
.van-field__control {
  height: 60px;
}
.van-field__label {
  /* margin-left: 25px; */
  font-size: 30px;
  letter-spacing: 20px;
  color: #deb77aff;
  padding-top: 9px;
  width: 145px;
}
.van-field__value {
  width: 349px;
  /* height: 45px; */
  /* border: 1px solid #DEB77A; */
  opacity: 1;
  border-radius: 0px;
}
.van-picker__confirm {
  font-size: 25px;
}
.van-picker__cancel {
  font-size: 25px;
}
.van-field__control {
  width: 349px;
  /* height: 45px; */
  border: 1px solid #deb77a;
  color: #deb77a;
  opacity: 1;
  border-radius: 0px;
  padding-left: 20px;
  box-sizing: border-box;
}
</style>